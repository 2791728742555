/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import ConfirmingInvoicesToSignTableComponent from 'app/confirming/invoicesToSignTable';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

export default function ConfirmingInvoicesToSignTableWithTitleComponent() {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.dashboard.invoicesToApprove.title" data-walkme="debtor-invoicesToApprove" />

      <Box mt={3}>
        <ConfirmingInvoicesToSignTableComponent />
      </Box>
    </React.Fragment>
  );
}
