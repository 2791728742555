/* @flow */

import IconAddFilled from 'components/icons/filled/add';
import IconArrowUpFilled from 'components/icons/filled/arrowUp';
import IconListFilled from 'components/icons/filled/list';
import IconHomeFilled from 'components/icons/filled/home';
import IconSuitcaseFilled from 'components/icons/filled/suitcase';
import IconRefreshFilled from 'components/icons/filled/refresh';
import IconUsersFilled from 'components/icons/filled/users';

import {
  PROTECTED_CONFIRMING_BILLS,
  PROTECTED_CONFIRMING_DASHBOARD, PROTECTED_CONFIRMING_SUBMIT,
} from 'constants/pageRoutes';

export const confirmingMenuData = [
  {link: PROTECTED_CONFIRMING_DASHBOARD, icon: <IconHomeFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.home'},
  {link: PROTECTED_CONFIRMING_SUBMIT, icon: <IconAddFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.submit', checkExecutePermissions: true, pageName: 'POS.Confirmings.SidebarMenu', elementName: 'menuSubmit'},
  {link: PROTECTED_CONFIRMING_BILLS, icon: <IconListFilled color='primary' size='1.5x'/>, messageId: 'sidebar.page.mybills', pageName: 'POS.Confirming.SidebarMenu', elementName: 'menuBills'},
];
