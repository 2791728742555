/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import InvoiceDocumentsComponent from 'components/invoiceDocuments';
import InvoiceNameComponent from 'components/invoice/name';
import LoadingComponent from 'components/loading';
import InvoiceBatchIconTooltipComponent from 'components/invoice/batchIcon';
import PageTitleComponent from 'components/pageTitle';

import SellerInvoiceDetailsActionsComponent from './actions';
import SellerInvoiceDetailsNotesComponent from './notes';
import SellerInvoiceDetailsPricesFlexComponent from './prices/flex';
import SellerInvoiceDetailsStatusComponent from './status';
import SellerInvoiceDetailsSummaryComponent from './summary';
import SellerInvoiceKPIsViewComponent from './kpis';
import SellerInvoiceBatchComponent from './batch';

import InvoiceBills from 'models/invoices/seller/invoiceBills';
import InvoiceDetails from 'models/invoices/seller/invoiceDetails';
import Kpi from 'models/dashboards/kpi';
import InvoiceBatch from 'models/invoices/seller/invoiceBatch';

import moment from 'moment/moment';

import DocumentService from 'services/DocumentService';
import InvoiceSellerService from 'services/InvoiceSellerService';
import NotificationHelper from 'lib/notifications';

type SellerInvoiceDetailsComponentProps = {
  activeCompanyId: number;
  invoiceId: number;
}

type SellerInvoiceDetailsComponentState = {
  invoice: ?InvoiceDetails;
  invoiceBatch: InvoiceBatch[];
  invoiceBills: InvoiceBills;
  invoiceKPIs: Kpi[];
  isLoading: boolean;
  titleValues: {id: Element<any>};
  errorCode: number;
}

class SellerInvoiceDetailsComponent extends React.Component<SellerInvoiceDetailsComponentProps, SellerInvoiceDetailsComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      invoiceBills: null,
      invoiceKPIs: null,
      isLoading: true,
      titleValues: {id: <b>{props.invoiceId}</b>},
      errorCode: 0
    };
  }

  componentDidMount() {
    this.loadInvoice(false);
  }

  // LOAD INVOICE
  loadInvoice = async (reload: boolean) => {
    if (reload) {
      this.setState({invoice: null, isLoading: true, titleValues: {id: <b>{this.props.invoiceId}</b>}});
    }

    try {
      const response = await InvoiceSellerService.getInvoiceDetails(this.props.activeCompanyId, this.props.invoiceId);
      this.setState({
        invoice: response.invoice,
        invoiceBatch: response.batch?.filter(b => !b.batchIsExcluded),
        invoiceBills: response.bills,
        invoiceKPIs: response.kpis,
        isLoading: false,
        titleValues: {id: <b><InvoiceNameComponent invoiceId={response.invoice.id} invoiceName={response.invoice.invoiceName} /></b>, 
                      reference: response.batch &&  response.batch.length > 0 ? <b>{response.invoice.reference}<InvoiceBatchIconTooltipComponent /></b> : <b>{response.invoice.reference}</b>
                    }
      });
    } catch (e) {
      console.error(e);
    }
  }

  downloadBill = async (billId: number) => {
    try {
      await DocumentService.downloadBill(this.props.activeCompanyId, billId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  renderLoading() {
    return (
      <React.Fragment>
        <PageTitleComponent id="page.seller.invoiceDetails.title.loading" values={this.state.titleValues} />
        <LoadingComponent />
      </React.Fragment>
    );
  }

  render() {
    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <React.Fragment>
        <PageTitleComponent id="page.seller.invoiceDetails.title" values={this.state.titleValues} backButton />

        <ComponentWrapperComponent small>
          <SellerInvoiceDetailsStatusComponent invoice={this.state.invoice} statusKeys={this.state.invoice.statusKeys} />

          <Box mt={5}>
            <SellerInvoiceDetailsSummaryComponent invoice={this.state.invoice} />

            {this.state.invoice.flexPrices && 
              <SellerInvoiceDetailsPricesFlexComponent
                invoice={this.state.invoice}
                invoiceBills={this.state.invoiceBills}
                downloadBill={this.downloadBill}
              />
            }

            <SellerInvoiceDetailsActionsComponent invoice={this.state.invoice} reloadAction={this.loadInvoice} />
          </Box>
        </ComponentWrapperComponent>

        <Box mt={5}>
          {this.state.invoiceBatch && this.state.invoiceBatch.length > 0 && <SellerInvoiceBatchComponent
            batch={this.state.invoiceBatch}
            showBalance={new moment(this.state.invoice.issueDate) > moment("23-02-2021", "DD-MM-YYYY")}
          />}
        </Box>
        
        {this.state.invoice.fixUnfinancedPrices &&
          <Box mt={5}>
            <SellerInvoiceDetailsNotesComponent key={`notes-${this.props.languageId}`} invoiceId={this.state.invoice.id} />
          </Box>
        }

        <Box mt={3}>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <InvoiceDocumentsComponent invoiceId={this.state.invoice.id} allowAdd={this.state.invoice.isDraft} />
            </Grid>
          
            <Grid item xs={12} md={6}>
              <SellerInvoiceKPIsViewComponent kpis={this.state.invoiceKPIs} />
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(SellerInvoiceDetailsComponent);
