/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';

import {ROLE_BUYER, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER} from 'constants/companyRoles';

type SecurityComponentProps = {
  elementName: string;
  pageName: string;
  checkActiveProfile: boolean;
  checkCanManageUsers: boolean;
  checkExecute: boolean;
  checkRoleExecute: boolean;
}

export default function SecurityComponent(props: SecurityComponentProps) {
  const {activeCompany, securityMenus} = useSelector(state => ({
    activeCompany: state?.auth?.user?.activeCompany,
    securityMenus: state?.locale?.securityMenus
  }));

  if (activeCompany === undefined)
  {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  // Can Manage Users (Debtors don't need to check this for now)
  if (props.checkCanManageUsers && !activeCompany.canManageUsers && activeCompany.role !== ROLE_DEBTOR) return null;

  // Execute permission
  if (props.checkExecute && !activeCompany.canExecute) return null;

  // Specific permission
  let hasAccess = true;

  // BUYER
  if (activeCompany.role === ROLE_BUYER) {
    if (hasAccess && props.checkRoleExecute) hasAccess = activeCompany.roleBuyer.canExecute;
    if (hasAccess && props.checkActiveProfile) hasAccess = activeCompany.investmentProfile.isActive;
    if (hasAccess && securityMenus.length > 0 && props.pageName && props.elementName) {
     const menu = securityMenus.find(m => m.pageName === props.pageName && m.elementName === props.elementName && m.userRoleId === activeCompany.roleBuyer.role && m.hasAccess);
     if (!menu) hasAccess = false;
    }
  }

  // DEBTOR
  else if (activeCompany.role === ROLE_DEBTOR) {
    if (hasAccess && props.checkRoleExecute) hasAccess = activeCompany.roleDebtor.canExecute;
    if (hasAccess && securityMenus.length > 0 && props.pageName && props.elementName) {
      const menu = securityMenus.find(m => m.pageName === props.pageName && m.elementName === props.elementName && m.userRoleId === activeCompany.roleDebtor.role && m.hasAccess);
      if (!menu) hasAccess = false;
    }
  }

  // ISO
  else if (activeCompany.role === ROLE_ISO) {
    if (!activeCompany.roleISO.isISOPortalActive) hasAccess = false;
    if (hasAccess && props.checkRoleExecute) hasAccess = activeCompany.roleISO.canExecute;
    if (hasAccess && securityMenus.length > 0 && props.pageName && props.elementName) {
      const menu = securityMenus.find(m => m.pageName === props.pageName && m.elementName === props.elementName && m.userRoleId === activeCompany.roleISO.role && m.hasAccess);
      if (!menu) hasAccess = false;
    }
  }

  // SELLER
  else if (activeCompany.role === ROLE_SELLER) {
    if (hasAccess && props.checkRoleExecute) hasAccess = activeCompany.roleSeller.canExecute;
    if (hasAccess && securityMenus.length > 0 && props.pageName && props.elementName) {
     const menu = securityMenus.find(m => m.pageName === props.pageName && m.elementName === props.elementName && m.userRoleId === activeCompany.roleSeller.role && m.hasAccess);
     if (!menu) hasAccess = false;
    }
  }

  // RESULT
  if (!hasAccess) return null;
  return <React.Fragment>{props.children}</React.Fragment>;
}
