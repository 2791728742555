/* @flow */

export default class SetCompanyBankAccountRequest {
  companyId: number;
  bankCountryId: number;
  bankIdentifier: string;
  bankAccount: string;
  buyerBlocked: boolean;
}

