/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import UploadButtonComponent from 'components/buttons/uploadButton';

type CompanyDocumentsUploadViewComponentProps = {
  isProcessing: boolean;
  handleFail: () => void;
  uploadFile: () => void;
}

export default function CompanyDocumentsUploadViewComponent(props: CompanyDocumentsUploadViewComponentProps) {
  return (
    <ComponentWrapperComponent>
      <PageSubTitleViewComponent id="component.company.documents.title" />
      
      <Box mt={3}>
        <UploadButtonComponent
          disabled={props.isProcessing}
          label="page.documents.form.button.upload"
          successHandler={props.uploadFile}
          failHandler={props.handleFail}
        />
      </Box>
    </ComponentWrapperComponent>
  );
}