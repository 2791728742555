/* @flow */

import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import Error404Page from 'components/errors/error404';
import RestrictedRoute from 'components/route/restrictedRoute';

import ConfirmingSubmitPage from './submit';
import ConfirmingDashboardPage from './dashboard';
import ConfirmingBillsPage from './bills';

import {PROTECTED_CONFIRMING_DASHBOARD} from 'constants/pageRoutes';

export default function ConfirmingPage() {
  const canExecute = useSelector(state => state.auth.user.activeCompany.roleConfirming.canExecute);
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={ConfirmingDashboardPage} />
      <Route path={`${match.url}/bills`} component={ConfirmingBillsPage} />
      <RestrictedRoute path={`${match.url}/submit`} component={ConfirmingSubmitPage} condition={canExecute} redirectUrl={PROTECTED_CONFIRMING_DASHBOARD} />
      <Route component={Error404Page} />
    </Switch>
  );
}
