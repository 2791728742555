/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import InvoiceProposal from 'models/invoices/seller/invoiceProposal';

type SellInvoiceTab5FlexPricesComponentProps = {
  invoice: InvoiceProposal;
  isProcessing: boolean;
  submitInvoice: () => void;
  cancelInvoice: () => void;
}

export default function SellInvoiceTab5FlexPricesComponent(props: SellInvoiceTab5FlexPricesComponentProps) {
  const totalFee = props.invoice.flexPrices.listingFee + props.invoice.flexPrices.serviceFee + props.invoice.flexPrices.insuranceFee;

  return (
    <React.Fragment>
      <Box mt={3} py={3} bgcolor="lightblue.main">
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box px={3}>
              <Box display="flex">
                <Box flexGrow={1} mb={1}>
                  <IntlMessageComponent id="page.sellInvoice.tab5.prices.flex.financedAmount" data-walkme="sell-t5-financedAmount" />
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.flexPrices.financedAmount} />
                </Box>
              </Box>

              <Box display="flex" fontWeight="fontWeightBold">
                <Box flexGrow={1} mb={1}>
                  <IntlMessageComponent id="page.sellInvoice.tab5.prices.flex.totalFee" data-walkme="sell-t5-totalFee" />
                </Box>
                <Box>
                  - <AmountFormatterComponent amount={totalFee} />
                </Box>
              </Box>

              <Box display="flex" pl={4} color="grey.main" fontSize={13}>
                <Box flexGrow={1} mb={1}>
                  <IntlMessageComponent id="page.sellInvoice.tab5.prices.flex.listingfee" data-walkme="sell-t5-listingfee" />
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.flexPrices.listingFee} />
                </Box>
              </Box>

              <Box display="flex" pl={4} color="grey.main" fontSize={13}>
                <Box flexGrow={1} mb={1}>
                  <IntlMessageComponent id="page.sellInvoice.tab5.prices.flex.servicefee" data-walkme="sell-t5-servicefee" />
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.flexPrices.serviceFee} />
                </Box>
              </Box>

              <Box display="flex" pl={4} color="grey.main" fontSize={13}>
                <Box flexGrow={1} mb={1}>
                  <IntlMessageComponent id="page.sellInvoice.tab5.prices.flex.insuranceFee" data-walkme="sell-t5-insuranceFee" />
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.flexPrices.insuranceFee} />
                </Box>
              </Box>

              <Box display="flex" pl={4} color="grey.main" fontSize={13}>
                <Box flexGrow={1} mb={1}>
                  <IntlMessageComponent id="page.submitinvoice.tab4.prices.buyerdiscount" />
                </Box>
                <Box>
                  <AmountFormatterComponent amount={props.invoice.flexPrices.buyerDiscount} />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={3}>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        <ButtonComponent
          color="default"
          variant="contained"
          disabled={props.isProcessing}
          onClick={props.cancelInvoice}
          data-walkme="sell-t5-cancel"
        ><IntlMessageComponent id="page.sellInvoice.tab5.button.cancel" /></ButtonComponent>

        <SecurityComponent pageName="POS.Sellers.SellInvoice" elementName="btnPayFees">
          <Box component="span" ml={1}>
            <ButtonComponent
              color="primary"
              variant="contained"
              isProcessing={props.isProcessing}
              onClick={props.submitInvoice}
            ><IntlMessageComponent id='page.submitinvoice.tab4.submit' /></ButtonComponent>
          </Box>
        </SecurityComponent>
      </Box>
    </React.Fragment>
  );
}
