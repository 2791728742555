/* @flow */

import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';

import NotificationSettings from 'models/users/notificationSettings';

import {ROLE_ISO} from 'constants/companyRoles';

type UserNotificationsViewComponentProps = {
  activeCompanyId: number,
  activeCompanyRole: number,
  activeCompanyIsDebtor: boolean,
  activeCompanyIsSeller: boolean,
  activeCompanyIsBuyer: boolean,
  hasChanges: boolean,
  isProcessed: boolean,
  isProcessing: boolean,
  notificationsSettings: NotificationSettings,
  handleSendBillEmails: () => void,
  handleSendPaymentEmails: () => void,
  handleSubmit: () => void,
  handleToggleNotification: () => void,
  handleToggleSMS: () => void
};

export default function UserNotificationsViewComponent(props: UserNotificationsViewComponentProps) {

  return (
    <React.Fragment>
      <Typography variant="h4" component="h2" gutterBottom>
        <IntlMessageComponent id="page.profile.notifications.subTitle" />
      </Typography>

      {/* <Typography variant="body1" gutterBottom>
        <IntlMessageComponent id="page.profile.notifications.description1" />
      </Typography> */}
      
      <Box mt={3}>
        {props.isProcessed &&
          <Alert severity="success">
            <IntlMessageComponent id="page.profile.notifications.success" />
          </Alert>
        }

        <List
          subheader={
            <ListSubheader>
              <IntlMessageComponent id={props.activeCompanyRole === ROLE_ISO ? 'page.profile.notifications.leads' : 'page.profile.notifications.invoices'} />
            </ListSubheader>
          }
        >
          {props.notificationsSettings.settings.map(setting => (
            <ListItem key={setting.id} role={undefined} dense button onClick={() => props.handleToggleNotification(setting.id)}>
              <Switch checked={setting.isChecked} />
              {props.activeCompanyRole === ROLE_ISO ? (
                <ListItemText primary={<IntlMessageComponent id={`lead.status.${setting.statusKey}`} />} />
              ) : (
                <ListItemText primary={<IntlMessageComponent id={`invoice.status.${setting.statusKey}`} />} />
              )}
            </ListItem>
          ))}
        </List>
        
        {(props.activeCompanyIsSeller || props.activeCompanyIsDebtor) && <List subheader={<ListSubheader><IntlMessageComponent id="page.profile.notifications.settings" /></ListSubheader>}>
          <ListItem role={undefined} dense button onClick={() => props.handleToggleSMS()}>
            <Switch checked={props.notificationsSettings.enableSMS} />
          </ListItem>
        </List>}

        {props.activeCompanyIsBuyer &&<List subheader={<ListSubheader><IntlMessageComponent id="page.profile.notifications.paymentemails" /></ListSubheader>}>
          <ListItem role={undefined} dense button onClick={() => props.handleSendPaymentEmails()}>
            <Switch checked={props.notificationsSettings.sendPaymentEmails} />
          </ListItem>
        </List>}

        <List subheader={<ListSubheader><IntlMessageComponent id="page.profile.notifications.emails" /></ListSubheader>}>
          <ListItem role={undefined} dense button onClick={() => props.handleSendBillEmails()}>
            <Switch checked={props.notificationsSettings.sendBillEmails} />
          </ListItem>
        </List>
        
      </Box>
      
      <Box mt={3}>
        <Typography variant="body1" gutterBottom>
          <IntlMessageComponent id="page.profile.notifications.description2" />
        </Typography>
        
        <ButtonComponent
          color="primary"
          variant="contained"
          disabled={!props.hasChanges}
          isProcessing={props.isProcessing}
          onClick={props.handleSubmit}
        ><IntlMessageComponent id="general.submit" /></ButtonComponent>
      </Box>
    </React.Fragment>
  );
}

