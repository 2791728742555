/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import LoadingComponent from 'components/loading';

import UserNotificationsViewComponent from './view';

import NotificationSettings from 'models/users/notificationSettings';
import UpdateNotificationSettingsRequest from 'models/requests/users/updateNotificationSettingsRequest';

import UserService from 'services/UserService';
import NotificationHelper from 'lib/notifications';

type UserNotificationsComponentProps = {
  activeCompanyId: number,
  activeCompanyRole: number,
  activeCompanyIsSeller: boolean,
  activeCompanyIsDebtor: boolean,
  activeCompanyIsBuyer: boolean
};

type UserNotificationsComponentState = {
  hasChanges: boolean,
  initialValues: Map<number, bool>,
  isLoading: boolean,
  isProcessed: boolean,
  isProcessing: boolean,
  notificationsSettings: NotificationSettings
};

class UserNotificationsComponent extends React.Component<UserNotificationsComponentProps, UserNotificationsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      hasChanges: false,
      initialValues: null,
      isLoading: true,
      isProcessed: false,
      isProcessing: false,
      notificationsSettings: null
    };
  }

  componentDidMount = async () => {
    try {
      const notificationsSettings = await UserService.getNotificationSettings(this.props.activeCompanyId, this.props.activeCompanyRole);
      const initialValues = this.computeInitialValues(notificationsSettings);
      this.setState({initialValues, isLoading: false, notificationsSettings});
    } catch (e) {
      console.error(e);
    }
  }

  computeInitialValues = (notificationsSettings: NotificationSettings) => {
    const map = new Map();
    notificationsSettings.settings.forEach(s => map.set(s.id, s.isChecked));
    // hardcode sms into statusId 10000 to check if has changed
    map.set(10000, notificationsSettings.enableSMS);
    map.set(10001, notificationsSettings.sendBillEmails);
    map.set(10002, notificationsSettings.sendPaymentEmails);
    return map;
  }

  checkHasChanges = (notificationsSettings: NotificationSettings, initialValues: Map<number, boolean>) => {
    // SMS changed
    if (notificationsSettings.enableSMS !== initialValues.get(10000)) return true;
    if (notificationsSettings.sendBillEmails !== initialValues.get(10001)) return true;
    if (notificationsSettings.sendPaymentEmails !== initialValues.get(10002)) return true;

    // Settings changed
    for (let i = 0; i < notificationsSettings.settings.length; i++) {
      const v = initialValues.get(notificationsSettings.settings[i].id);
      if (notificationsSettings.settings[i].isChecked !== v) return true;
    }

    return false;
  }

  handleToggleSMS = () => {
    const notificationsSettings = this.state.notificationsSettings;
    notificationsSettings.enableSMS = !notificationsSettings.enableSMS;
    const hasChanges = this.checkHasChanges(notificationsSettings, this.state.initialValues);
    this.setState({hasChanges, isProcessed: false, notificationsSettings});
  }

  handleSendBillEmails = () => {
    const notificationsSettings = this.state.notificationsSettings;
    notificationsSettings.sendBillEmails = !notificationsSettings.sendBillEmails;
    const hasChanges = this.checkHasChanges(notificationsSettings, this.state.initialValues);
    this.setState({hasChanges, isProcessed: false, notificationsSettings});
  }

  handleSendPaymentEmails = () => {
    const notificationsSettings = this.state.notificationsSettings;
    notificationsSettings.sendPaymentEmails = !notificationsSettings.sendPaymentEmails;
    const hasChanges = this.checkHasChanges(notificationsSettings, this.state.initialValues);
    this.setState({hasChanges, isProcessed: false, notificationsSettings});
  }

  handleToggleNotification = (id: number) => {
    const notificationsSettings = this.state.notificationsSettings;
    const setting = notificationsSettings.settings.find(s => s.id === id);
    if (setting.isFixed) return;
    setting.isChecked = !setting.isChecked;
    const hasChanges = this.checkHasChanges(notificationsSettings, this.state.initialValues);
    this.setState({hasChanges, isProcessed: false, notificationsSettings});
  }

  handleSubmit = async () => {
    try {
      this.setState({isProcessed: false, isProcessing: true});
      const request = new UpdateNotificationSettingsRequest(this.props.activeCompanyId, this.props.activeCompanyRole, this.state.notificationsSettings);
      await UserService.setNotificationSettings(request);
      const initialValues = this.computeInitialValues(this.state.notificationsSettings);
      this.setState({hasChanges: false, initialValues, isProcessed: true, isProcessing: false});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <UserNotificationsViewComponent
        activeCompanyId={this.props.activeCompanyId}
        activeCompanyRole={this.props.activeCompanyRole}
        activeCompanyIsSeller={this.props.activeCompanyIsSeller}
        activeCompanyIsDebtor={this.props.activeCompanyIsDebtor}
        activeCompanyIsBuyer={this.props.activeCompanyIsBuyer}
        hasChanges={this.state.hasChanges}
        isProcessed={this.state.isProcessed}
        isProcessing={this.state.isProcessing}
        notificationsSettings={this.state.notificationsSettings}
        handleSendBillEmails={this.handleSendBillEmails}
        handleSendPaymentEmails={this.handleSendPaymentEmails}
        handleSubmit={this.handleSubmit}
        handleToggleNotification={this.handleToggleNotification}
        handleToggleSMS={this.handleToggleSMS}
      />
    );
  }
}

const mapStateToProps = ({auth}) => ({
  activeCompanyId: auth.user.activeCompany.id,
  activeCompanyRole: auth.user.activeCompany.role,
  activeCompanyIsSeller: auth.user.activeCompany.isSeller,
  activeCompanyIsDebtor: auth.user.activeCompany.isDebtor,
  activeCompanyIsBuyer: auth.user.activeCompany.isBuyer
});

export default connect(mapStateToProps)(UserNotificationsComponent);
