import React from 'react';

import ButtonComponent from 'components/buttons/button';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import UploadComponent from 'components/upload';

type UploadButtonComponentProps = {
  disabled: boolean;
  label: string;
  successHandler: (file: File) => void;
  failHandler: () => void;
}

export default function UploadButtonComponent(props: UploadButtonComponentProps) {
  return (
    <React.Fragment>
      <UploadComponent
        accept="application/pdf, image/jpeg, image/png"
        disabled={props.disabled}
        successHandler={props.successHandler}
        failHandler={props.failHandler}
      >
        <ButtonComponent
          color="primary"
          variant="contained"
          disabled={props.disabled}
          onClick={props.open}
        ><IntlMessageComponent id={props.label} /></ButtonComponent>
      </UploadComponent>

      <MandatoryFormFieldsComponent id="general.file.accept" values={{accept: "application/pdf, image/jpeg, image/png", size: 5}} />
    </React.Fragment>
  );
}
