/* @flow */

import {plainToClass} from 'class-transformer';

import BaseService from './api/baseService';

import {
  BUYER_ACCOUNTING_EXPORT_GET,
  BUYER_INVESTMENT_PROFILES_DEFAULTS_GET,
  BUYER_INVESTMENT_PROFILES_GET,
  BUYER_INVESTMENT_PROFILES_POST,
  BUYER_INVESTMENT_PROFILES_NAME_POST,
  BUYER_INVESTMENT_PROFILES_NEW,
  BUYER_INVESTMENT_PROFILES_DISABLE_POST,
  BUYER_INVOICE_DETAILS_GET,
  BUYER_INVOICES_CONFIRM_PAYMENT_POST,
  BUYER_INVOICES_GET,
  BUYER_INVOICES_PAY_GET,
  BUYER_INVOICES_PURCHASE_GET,
  BUYER_INVOICES_PURCHASE_POST,
  BUYER_INVOICES_REFUSE_POST,
  BUYER_KPIS_GET,
  BUYER_PERFORMANCE_GET,
  BUYER_PORTFOLIO_DATE_GET,
  BUYER_PORTFOLIO_EXPORT_GET,
  BUYER_PORTFOLIO_GET,  
  BUYER_PROFILE_SUMMARY_GET,
  BUYER_PAYMENT_REPORT_GET
} from 'constants/apiRoutes';

import ConfirmInvoicePaymentRequest from 'models/requests/buyers/confirmInvoicePaymentRequest';
import DisableInvestmentProfileRequest from 'models/requests/buyers/disableInvestmentProfileRequest';
import DownloadAccountingRequest from 'models/requests/buyers/downloadAccountingRequest';
import DownloadPortfolioRequest from 'models/requests/buyers/downloadPortfolioRequest';
import DownloadPaymentReportRequest from 'models/requests/buyers/downloadPaymentReportRequest';
import GetInvoicesToPayRequest from 'models/requests/buyers/getInvoicesToPayRequest';
import GetPerformanceRequest from 'models/requests/buyers/getPerformanceRequest';
import PurchaseInvoiceRequest from 'models/requests/buyers/purchaseInvoiceRequest';
import RefuseInvoiceRequest from 'models/requests/buyers/refuseInvoiceRequest';
import UpdateInvestmentProfileRequest from 'models/requests/buyers/updateInvestmentProfileRequest';
import AddInvestmentProfileRequest from 'models/requests/buyers/addInvestmentProfileRequest';

import BankAccount from 'models/companies/bankAccount';
import BuyerInvestmentProfile from 'models/buyers/investmentProfiles/investmentProfile';
import BuyerInvestmentProfileDefaults from 'models/buyers/investmentProfiles/investmentProfileDefaults';
import BuyerInvestmentProfileValues from 'models/buyers/investmentProfiles/investmentProfileValues';
import BuyerInvestmentSummaryOverview from 'models/buyers/dashboards/investmentSummaryOverview';
import BuyerInvestmentSummaryPerformance from 'models/buyers/dashboards/investmentSummaryPerformance';
import BuyerInvoice from 'models/buyers/invoices/invoice';
import BuyerInvoiceBatch from 'models/buyers/invoices/invoiceBatch';
import BuyerPerformance from 'models/buyers/performance';
import Company from 'models/companies/company';
import DebtorCompany from 'models/companies/debtorCompany';
import InvoiceNotes from 'models/buyers/invoices/invoiceNotes';
import InvoiceFilters from 'models/buyers/invoices/invoiceFilters';
import Kpi from 'models/dashboards/kpi';
import Note from 'models/generics/note';
import ProfileSummaryCard from 'models/dashboards/profileSummaryCard';
import WireTransferPayment from 'models/payments/wireTransferPayment';

export default class BuyerService {

  // Dashboard
  static getKPIs = (companyId: number, investmentProfileId: number) =>
    BaseService.simpleGET(`${BUYER_KPIS_GET}/${companyId}/${investmentProfileId}`,
    data => data.map(d => plainToClass(Kpi, d)));

  static getProfileSummary = (companyId: number, investmentProfileId: number) =>
    BaseService.simpleGET(`${BUYER_PROFILE_SUMMARY_GET}/${companyId}/${investmentProfileId}`,
    data => ({
      cards: data.cards.map(d => plainToClass(ProfileSummaryCard, d)),
      investmentOverview: plainToClass(BuyerInvestmentSummaryOverview, data.investmentOverview),
      investmentPerformance: plainToClass(BuyerInvestmentSummaryPerformance, data.investmentPerformance)
    }));

  // Invoices - Buy
  static confirmInvoicePayment = (request: ConfirmInvoicePaymentRequest) =>
    BaseService.simplePOST(BUYER_INVOICES_CONFIRM_PAYMENT_POST, request, null);

  static downloadAccounting = (request: DownloadAccountingRequest) =>
    BaseService.simpleFileDownloadGET(`${BUYER_ACCOUNTING_EXPORT_GET}?${request.toRouteParameters()}`);
    
  static downloadPayment = (request: DownloadPaymentReportRequest) =>
    BaseService.simpleFileDownloadGET(`${BUYER_PAYMENT_REPORT_GET}?${request.toRouteParameters()}`);

  static downloadPortfolio = (request: DownloadPortfolioRequest) =>
    BaseService.simpleFileDownloadGET(`${BUYER_PORTFOLIO_EXPORT_GET}?${request.toRouteParameters()}`);

  static getInvestmentProfile = (companyId: number, investmentProfileId: number) =>
    BaseService.simpleGET(`${BUYER_INVESTMENT_PROFILES_GET}/${companyId}/${investmentProfileId}`,
      data => {
        const current = plainToClass(BuyerInvestmentProfileValues, data.currentProfile);
        current.bankAccount = plainToClass(BankAccount, data.currentProfile.bankAccount);
        const requested = plainToClass(BuyerInvestmentProfileValues, data.requestedProfile);
        requested.bankAccount = data.requestedProfile.bankAccount ? plainToClass(BankAccount, data.requestedProfile.bankAccount) : null;
        return {isActive: data.isActive, current, requested, hasInvoicesListedTo: data.hasInvoicesListedTo};
      });

  static getInvestmentProfileDefaults = () =>
    BaseService.simpleGET(BUYER_INVESTMENT_PROFILES_DEFAULTS_GET, data => plainToClass(BuyerInvestmentProfileDefaults, data));

  static getInvoiceDetails = (companyId: number, invoiceId: number) =>
    BaseService.simpleGET(`${BUYER_INVOICE_DETAILS_GET}/${companyId}/${invoiceId}`,
    data => {
      if (!data.invoice) return null;
      const invoice = BuyerService.mapInvoice(data.invoice);
      const batch = BuyerService.mapInvoiceBatch(data.batch);
      
      let kpis = null;
      if (data.kpis) {
        kpis = data.kpis.map(d => plainToClass(Kpi, d));
      }

      let notes = null;
      if (data.notes) {
        notes = plainToClass(InvoiceNotes, data.notes);
        notes.actions = data.notes.actions.map(n => plainToClass(Note, n));
        notes.outlook = plainToClass(Note, data.notes.outlook);
      }

      return {invoice, kpis, notes, batch, statusKeys: data.statusKeys};
    });

  static getInvoices = (companyId: number, investmentProfileId: number) =>
    BaseService.simpleGET(`${BUYER_INVOICES_GET}/${companyId}/${investmentProfileId}`,
      data => data.map(inv => BuyerService.mapInvoice(inv)));
  
  static getInvoicesPortfolio = (companyId: number, investmentProfileId: number) =>
    BaseService.simpleGET(`${BUYER_PORTFOLIO_GET}/${companyId}/${investmentProfileId}`,
    data => { 
      const invoices = data.invoices.map(inv => BuyerService.mapInvoice(inv));
      const filters = BuyerService.mapInvoiceFilters(data.filters);
      return {invoices, filters};
    });
  
  static getInvoicesPortfolioOnDate = (companyId: number, investmentProfileId: number, date: moment) =>
    BaseService.simpleGET(`${BUYER_PORTFOLIO_DATE_GET}/${companyId}/${investmentProfileId}/${date.format('YYYY-MM-DD')}`,
    data => {
      const invoices = data.invoices.map(inv => BuyerService.mapInvoice(inv));
      const filters = BuyerService.mapInvoiceFilters(data.filters);
      return {invoices, filters};
    });

  static getInvoicesToPay = (request: GetInvoicesToPayRequest) =>
    BaseService.simpleGET(`${BUYER_INVOICES_PAY_GET}?${request.toRouteParameters()}`,
      data => {
        const invoices = data.invoices.map(i => BuyerService.mapInvoice(i));
        const isMandated = data.isMandated;
        const wireTransferPayment = data.payment ? plainToClass(WireTransferPayment, data.payment) : null;
        return {invoices, isMandated, wireTransferPayment};
      });
    
  static getInvoicesToPurchase = (companyId: number, investmentProfileId: number, includeInvoicesToPay: boolean) =>
    BaseService.simpleGET(`${BUYER_INVOICES_PURCHASE_GET}/${companyId}/${investmentProfileId}/${includeInvoicesToPay.toString()}`,
      data => data.map(inv => BuyerService.mapInvoice(inv)));
  
  static getPerformance = (request: GetPerformanceRequest) =>
    BaseService.simpleGET(`${BUYER_PERFORMANCE_GET}?${request.toRouteParameters()}`, data => plainToClass(BuyerPerformance, data));

  static purchaseInvoice = (request: PurchaseInvoiceRequest) =>
    BaseService.simplePOST(BUYER_INVOICES_PURCHASE_POST, request, null);

  static refuseInvoice = (request: RefuseInvoiceRequest) =>
    BaseService.simplePOST(BUYER_INVOICES_REFUSE_POST, request, null);

  static updateInvestmentProfile = (request: UpdateInvestmentProfileRequest) =>
    BaseService.simplePOST(BUYER_INVESTMENT_PROFILES_POST, request, null);

  static updateInvestmentProfileName = (request: UpdateInvestmentProfileRequest) =>
    BaseService.simplePOST(BUYER_INVESTMENT_PROFILES_NAME_POST, request, null);

  static addInvestmentProfile = (request: AddInvestmentProfileRequest) =>
    BaseService.simplePOST(BUYER_INVESTMENT_PROFILES_NEW, request, null);

  static disableInvestmentProfile = (request: DisableInvestmentProfileRequest) =>
    BaseService.simplePOST(BUYER_INVESTMENT_PROFILES_DISABLE_POST, request, null);

  static mapInvoice = (inv) => {
    const invoice = plainToClass(BuyerInvoice, inv);
    invoice.sellerCompany = plainToClass(Company, inv.sellerCompany);
    invoice.debtorCompany = plainToClass(DebtorCompany, inv.debtorCompany);
    invoice.investmentProfile = plainToClass(BuyerInvestmentProfile, inv.investmentProfile);
    return invoice;
  }

  static mapInvoiceBatch = (batch) => {
    const batchArray = [];
    batch.forEach((invoiceBatch) => {
      const mappedInvoiceBatch = plainToClass(BuyerInvoiceBatch, invoiceBatch);
      batchArray.push(mappedInvoiceBatch);
    })
    return batchArray;
  }

  static mapInvoiceFilters = (data) => {
    const filters = new InvoiceFilters();
    filters.statusKeyClosed = data.statusKeyClosed;
    filters.statusKeys = data.statusKeys;
    return filters;
  }
}
