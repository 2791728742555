/* @flow */

import { plainToClass } from 'class-transformer';

import BaseService from './api/baseService';

import {
  COMPANY_DETAILS_GET,
  COMPANY_DETAILS_POST,
  COMPANY_DETAILS_ISO_POST,
  COMPANY_LEGAL_FORMS_GET,
  COMPANY_POST_DEBTOR,
  COMPANY_POST_COMPANY,
  COMPANY_BANK_ACCOUNTS_GET,
  COMPANY_BANK_ACCOUNTS_POST,
  COMPANY_BANK_ACCOUNTS_TOGGLE_ENABLE,
  COMPANY_BANK_ACCOUNTS_DELETE,
  COMPANY_BANK_ACCOUNTS_MAIN_POST,
  COMPANY_MEMBERSHIP_APPLY_COUPON_POST,
  COMPANY_MEMBERSHIP_GET,
  COMPANY_MEMBERSHIP_POST,
  COMPANY_USERS_GET,
  COMPANY_USERS_ADD_POST,
  COMPANY_USERS_ROLES_ADD_POST,
  COMPANY_USERS_UPDATE_POST,
  COMPANY_USERS_ROLES_UPDATE_POST,
  COMPANY_USERS_PROCURATION_GET,
  COMPANY_USERS_PROCURATION_POST,
  COMPANY_USERS_DISABLE,
  COMPANY_CONTACTS_GET,
  COMPANY_CONTACTS_POST,
  COMPANY_PERSONS_ISO_WITHOUT_OFFICE_GET,
  COMPANY_PERSONS_DEBTOR_WITHOUT_OFFICE_GET,
  COMPANY_UBOS_GET,
  COMPANY_UBOS_CONFIRM_POST,
  COMPANY_UBOS_ADD_POST,
  COMPANY_UBOS_UPDATE_PUT,
  COMPANY_UBOS_DISABLE_POST,
  COMPANY_UBOS_ENABLE_POST,
  COMPANY_FIX_CONTRACT_GET,
  COMPANY_POST_SUPPLIER
} from 'constants/apiRoutes';

import AddCompanyBankAccountRequest from 'models/requests/companies/addCompanyBankAccountRequest';
import AddCompanyContactPersonRequest from 'models/requests/companies/addCompanyContactPersonRequest';
import AddCompanyUserRequest from 'models/requests/companies/users/addCompanyUserRequest';
import AddCompanyUserWithOfficeRequest from 'models/requests/companies/users/addCompanyUserWithOfficeRequest';
import AddNewCompanyRequest from 'models/requests/companies/addNewCompanyRequest';
import ToggleEnableCompanyBankAccountRequest from 'models/requests/companies/toggleEnableCompanyBankAccountRequest';
import RenewCompanyMembershipRequest from 'models/requests/companies/renewCompanyMembershipRequest';
import SetCompanyMembershipCouponRequest from 'models/requests/companies/setCompanyMembershipCouponRequest';
import SetMainCompanyBankAccountRequest from 'models/requests/companies/setMainCompanyBankAccountRequest';
import UpdateCompanyDetailsRequest from 'models/requests/companies/updateCompanyDetailsRequest';
import UpdateCompanyUserProcurationRequest from 'models/requests/companies/users/updateCompanyUserProcurationRequest';
import UpdateCompanyUserRequest from 'models/requests/companies/users/updateCompanyUserRequest';
import UpdateCompanyUserWithOfficeRequest from 'models/requests/companies/users/updateCompanyUserWithOfficeRequest';
import DisableCompanyUserRequest from 'models/requests/companies/users/disableCompanyUserRequest';

import AddCompanyUBORequest from 'models/requests/companies/ubos/addCompanyUBORequest';
import ConfirmCompanyUBOsRequest from 'models/requests/companies/ubos/confirmCompanyUBOsRequest';
import DisableCompanyUBORequest from 'models/requests/companies/ubos/disableCompanyUBORequest';
import EnableCompanyUBORequest from 'models/requests/companies/ubos/enableCompanyUBORequest';
import UpdateCompanyUBORequest from 'models/requests/companies/ubos/updateCompanyUBORequest';

import BankAccount from 'models/companies/bankAccount';
import CompanyContactPerson from 'models/companies/companyContactPerson';
import CompanyDetails from 'models/companies/companyDetails';
import CompanyLegalForm from 'models/companies/companyLegalForm';
import CompanyMembership from 'models/companies/companyMembership';
import CompanyFixContract from 'models/companies/companyFixContract';
import CompanyPerson from 'models/companies/companyPerson';
import CompanyUBO from 'models/companies/companyUBO';
import CompanyUser from 'models/companies/companyUser';
import CompanyUserProcuration from 'models/companies/companyUserProcuration';
import CompanyUserDetails from 'models/companies/companyUserDetails';
import DebtorCompany from 'models/companies/debtorCompany';
import Company from 'models/companies/company';
import PaymentDetail from 'models/payments/paymentDetail';
import PaymentInfo from 'models/payments/paymentInfo';
import PaymentOgone from 'models/payments/paymentOgone';
import PaymentOgoneValue from 'models/payments/paymentOgoneValue';
import AddCompanyUnknownRequest from 'models/requests/companies/addCompanyUnknownRequest';
import DeleteCompanyBankAccountRequest from 'models/requests/companies/deleteCompanyBankAccountRequest';

export default class CompanyService {

  static getCompanyDetails = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_DETAILS_GET}/${companyId}`,
      data => plainToClass(CompanyDetails, data));

  static updateCompanyDetails = (request: UpdateCompanyDetailsRequest) =>
    BaseService.simplePOST(COMPANY_DETAILS_POST, request, null);

  static updateISOCompanyDetails = (request: UpdateCompanyDetailsRequest) =>
    BaseService.simplePOST(COMPANY_DETAILS_ISO_POST, request, null);

  static addNewCompany = (request: AddNewCompanyRequest) =>
    BaseService.simplePOST(COMPANY_POST_COMPANY, request, null);

  static addDebtorCompany = (insurerId: string) =>
    BaseService.anonymousPOST(`${COMPANY_POST_DEBTOR}/${insurerId}`, {}, dc => plainToClass(DebtorCompany, dc));

  static addSupplierCompany = (insurerId: string) =>
    BaseService.anonymousPOST(`${COMPANY_POST_SUPPLIER}/${insurerId}`, {}, dc => plainToClass(Company, dc));

  static addDebtorCompanyUnknown = (request: AddCompanyUnknownRequest) =>
    BaseService.anonymousPOST(`${COMPANY_POST_DEBTOR}`, request, dc => plainToClass(DebtorCompany, dc));

  static getLegalForms = (countryId: number) =>
    BaseService.simpleGET(`${COMPANY_LEGAL_FORMS_GET}/${countryId}`,
      data => data.map(d => plainToClass(CompanyLegalForm, d)));

  static getCompanyBankAccounts = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_BANK_ACCOUNTS_GET}/${companyId}`,
      data => data.map(d => plainToClass(BankAccount, d)));

  static addCompanyBankAccount = (request: AddCompanyBankAccountRequest) =>
    BaseService.simplePOST(COMPANY_BANK_ACCOUNTS_POST, request, null);

  static toggleEnableCompanyBankAccount = (request: ToggleEnableCompanyBankAccountRequest) =>
    BaseService.simplePOST(COMPANY_BANK_ACCOUNTS_TOGGLE_ENABLE, request, null);

  static deleteCompanyBankAccount = (request: DeleteCompanyBankAccountRequest) =>
    BaseService.simplePOST(COMPANY_BANK_ACCOUNTS_DELETE, request, null);

  static setMainCompanyBankAccount = (request: SetMainCompanyBankAccountRequest) =>
    BaseService.simplePOST(COMPANY_BANK_ACCOUNTS_MAIN_POST, request, null);

  // Company Users
  static getCompanyUsers = (companyId: number, companyRole: number) =>
    BaseService.simpleGET(`${COMPANY_USERS_GET}/${companyId}/${companyRole}`,
      data => data.map(d => plainToClass(CompanyUser, d)));

  static getCompanyUserDetails = (companyId: number, companyRole: number, companyUserId: number) =>
    BaseService.simpleGET(`${COMPANY_USERS_GET}/${companyId}/${companyRole}/${companyUserId}`,
      data => plainToClass(CompanyUserDetails, data));

  static addCompanyUser = (request: AddCompanyUserRequest) =>
    BaseService.simplePOST(COMPANY_USERS_ADD_POST, request, null);

  static addCompanyUserRoles = (request: AddCompanyUserWithOfficeRequest) =>
    BaseService.simplePOST(COMPANY_USERS_ROLES_ADD_POST, request, null);

  static updateCompanyUser = (request: UpdateCompanyUserRequest) =>
    BaseService.simplePOST(COMPANY_USERS_UPDATE_POST, request, null);

  static updateCompanyUserRoles = (request: UpdateCompanyUserWithOfficeRequest) =>
    BaseService.simplePOST(COMPANY_USERS_ROLES_UPDATE_POST, request, null);

  static getCompanyUserProcuration = (companyId: number, companyUserId: number) =>
    BaseService.simpleGET(`${COMPANY_USERS_PROCURATION_GET}/${companyId}/${companyUserId}`,
      data => plainToClass(CompanyUserProcuration, data));

  static updateCompanyUserProcuration = (request: UpdateCompanyUserProcurationRequest) =>
    BaseService.simplePOST(COMPANY_USERS_PROCURATION_POST, request, null);

  static getContactPersons = (sellerCompanyId: number, debtorCompanyId: number) =>
    BaseService.simpleGET(`${COMPANY_CONTACTS_GET}/${sellerCompanyId}/${debtorCompanyId}`,
      data => data.map(d => plainToClass(CompanyContactPerson, d)));

  static addContactPerson = (request: AddCompanyContactPersonRequest) =>
    BaseService.simplePOST(COMPANY_CONTACTS_POST, request, cp => plainToClass(CompanyContactPerson, cp));

  static getISOCompanyPersonsWithoutOffice = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_PERSONS_ISO_WITHOUT_OFFICE_GET}/${companyId}`,
      data => data.map(d => plainToClass(CompanyPerson, d)));

  static getDebtorCompanyPersonsWithoutOffice = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_PERSONS_DEBTOR_WITHOUT_OFFICE_GET}/${companyId}`,
      data => data.map(d => plainToClass(CompanyPerson, d)));

  static disableCompanyUser = (request: DisableCompanyUserRequest) =>
    BaseService.simplePOST(COMPANY_USERS_DISABLE, request, null);


  // UBO

  static getCompanyUBOs = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_UBOS_GET}/${companyId}`,
      data => ({
        ubos: data.companyUBOs.map(d => plainToClass(CompanyUBO, d)),
        status: data.status
      }));

  static getCompanyPersonsWithoutUBO = (companyId: number) =>
    BaseService.simpleGET(`${COMPANY_UBOS_GET}/noubo/${companyId}`,
      data => ({
        ubos: data.companyUBOs.map(d => plainToClass(CompanyUBO, d)),
        status: data.status
      }));

  static confirmCompanyUBOs = (request: ConfirmCompanyUBOsRequest) =>
    BaseService.simplePOST(COMPANY_UBOS_CONFIRM_POST, request, null);

  static addCompanyUBO = (request: AddCompanyUBORequest) =>
    BaseService.simplePOST(COMPANY_UBOS_ADD_POST, request, data => data);

  static updateCompanyUBO = (request: UpdateCompanyUBORequest) =>
    BaseService.simplePUT(COMPANY_UBOS_UPDATE_PUT, request, null);

  static disableCompanyUBO = (request: DisableCompanyUBORequest) =>
    BaseService.simplePOST(COMPANY_UBOS_DISABLE_POST, request, null);

  static enableCompanyUBO = (request: EnableCompanyUBORequest) =>
    BaseService.simplePOST(COMPANY_UBOS_ENABLE_POST, request, null);


  // Membership

  static getMemberships = (companyId: Number, paymentMethod: string) =>
    BaseService.simpleGET(`${COMPANY_MEMBERSHIP_GET}/${companyId}/${paymentMethod}`,
      data => data.map(d => {
        const membership = plainToClass(CompanyMembership, d);

        if (d.payment) {
          membership.paymentInfo = plainToClass(PaymentInfo, d.payment);
          membership.paymentInfo.details = d.payment.details.map((d) => {
            const details = plainToClass(PaymentDetail, d);
            details.translationKey = `membership.payment.type.${details.type}`;
            return details;
          });

          if (d.payment.ogone) {
            membership.paymentInfo.ogone = plainToClass(PaymentOgone, d.payment.ogone);
            membership.paymentInfo.ogone.formValues = d.payment.ogone.formValues.map(fv => plainToClass(PaymentOgoneValue, fv));
          }
        }

        delete membership.payment;
        return membership;
      }));

  static renewCompanyMembership = (request: RenewCompanyMembershipRequest) =>
    BaseService.simplePOST(COMPANY_MEMBERSHIP_POST, request, null);

  static setCompanyMembershipCoupon = (request: SetCompanyMembershipCouponRequest) =>
    BaseService.simplePOST(COMPANY_MEMBERSHIP_APPLY_COUPON_POST, request, null);

  //FIx Contract
  static getFixContracts = (companyId: Number) =>
    BaseService.simpleGET(`${COMPANY_FIX_CONTRACT_GET}/${companyId}`,
      data => data.map(d => plainToClass(CompanyFixContract, d)));
}
