/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import IntlMessageComponent from 'components/formatters/intlMessage';
import SecurityComponent from 'components/security/index';

import { sellerMenuData } from './data';

const useStyles = makeStyles(theme => ({
  menuItem: {
    borderTop: `1px solid ${theme.palette.lightgrey.main}`
  },
  menuText: {
    marginLeft: '.5em',
    verticalAlign: 'middle'
  },
  menuIcon: {
    verticalAlign: 'middle'
  }
}));

export default function SellerWebsiteMenuComponent() {
  const roleSeller = useSelector(state => state.auth.user.activeCompany.roleSeller);
  const classes = useStyles();

  return (
    <React.Fragment>
      {sellerMenuData.map((menuElement, i) => {
        if (menuElement.checkExecutePermissions && !roleSeller.canExecute) {
          return null;
        }
        if (menuElement.checkOfferPermissions && !roleSeller.canRequestOffers) {
          return null;
        }

        return (menuElement.pageName && menuElement.elementName ?
          <SecurityComponent key={`sidenav-${i}`} pageName={menuElement.pageName} elementName={menuElement.elementName}>
            <li key={`sidenav-${i}`} className={`menu no-arrow ${classes.menuItem}`}>
              <NavLink to={menuElement.link}>
                {menuElement.icon}
                <Box component="span" className={`nav-text ${classes.menuText}`}>
                  <b><IntlMessageComponent id={menuElement.messageId} /></b>
                </Box>
              </NavLink>
            </li>
          </SecurityComponent> :
          <li key={`sidenav-${i}`} className={`menu no-arrow ${classes.menuItem}`}>
            <NavLink to={menuElement.link}>
              {menuElement.icon}
              <Box component="span" className={`nav-text ${classes.menuText}`}>
                <b><IntlMessageComponent id={menuElement.messageId} /></b>
              </Box>
            </NavLink>
          </li>
        );
      })}
    </React.Fragment>
  );
}
