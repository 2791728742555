/* @flow */

export default class CompanyUserProcuration {
  companyUserId: number;

  companyIsBuyer: boolean;
  companyIsDebtor: boolean;
  companyIsISO: boolean;
  companyIsSeller: boolean;
  companyIsConfirming: boolean;

  isAdminRole: boolean;

  hasProcurationAsBuyer: boolean;
  hasProcurationAsDebtor: boolean;
  hasProcurationAsISO: boolean;
  hasProcurationAsSeller: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
  hasProcurationDebtRecognition: boolean;

  isObserver: boolean;
}
