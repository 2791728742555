/* @flow */

import moment from 'moment/moment';

export default class Invoice {
  id: number;
  invoiceName: number;

  type: number;
  statusKey: string;
  
  reference: string;
  
  debtorName: string;
  debtorSpeedometer: string;
  insurerRating: number;

  debtAmount: number;
  financedAmount: number;
  transactionPrice: number;
  cashToTransfer: number;
  discountPct: number;
  transferedAmount: number;
  
  dueDate: moment;
  createdDate: moment;
  purchasedDate: moment;
  estimateDate: moment;
  overdueDays: number;

  qrCodeSeller: string;
  qrCodeBuyer: string

  serviceFee: number;

  isForFinancing: boolean;
  outstandingDebtAmountBalance: number;
  requiresPayingFees: boolean;
  closedForDebtor: moment;

  isSelected: boolean;
}
