/* @flow */

import moment from 'moment';

export default class Bill {
  id: number;
  reference: string;
  issueDate: moment;
  typeId: number;
  billedTypeId: number;
  amount: number;
  debtorCompanyName: string;
  invoiceId: number;
  invoiceName: number;
  invoiceReference: string;

  isSelected: boolean;
}
