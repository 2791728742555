/* @flow */

import moment from 'moment/moment';

import DebtorCompany from 'models/companies/debtorCompany';
import InvoiceFixPrices from './invoiceFixPrices';
import InvoiceFlexPrices from './invoiceFlexPrices';

export default class InvoiceDetails {
  id: number;
  invoiceName: string;

  reference: string;

  issueDate: moment;
  dueDate: moment;

  debtorCompany: DebtorCompany;

  debtAmount: number;

  flexPrices: InvoiceFlexPrices;
  fixFinancedPrices: InvoiceFixPrices;
  fixUnfinancedPrices: InvoiceFixPrices;

  statusKey: string;
  statusKeys: string[];

  isDraft: boolean;
  hasFeesToPay: boolean;

  isCancelledBySeller: boolean;
  isRefused: boolean;
}
