/* @flow */

import React from 'react';
import {NotificationManager} from 'react-notifications';

import IntlMessageComponent from 'components/formatters/intlMessage';

export default class NotificationHelper {

  static TYPE_INFO = 'info';
  static TYPE_SUCCESS = 'success';
  static TYPE_WARNING = 'warning';
  static TYPE_ERROR = 'error';

  static SHOWN_TIME = 5000;

  static TYPES = [
    NotificationHelper.TYPE_INFO,
    NotificationHelper.TYPE_SUCCESS,
    NotificationHelper.TYPE_WARNING,
    NotificationHelper.TYPE_ERROR,
  ];


  static createNotification = (type: string, message: string) => {

    if (!NotificationHelper.TYPES.includes(type)) {
      throw new Error(`NotificationHelper - unsupported type ${type}`);
    }

    let title;
    switch (type) {
      case NotificationHelper.TYPE_INFO:
        title = <IntlMessageComponent id="notifications.info" />
        break;
      case NotificationHelper.TYPE_SUCCESS:
        title = <IntlMessageComponent id="notifications.success" />
        break;
      case NotificationHelper.TYPE_WARNING:
        title = <IntlMessageComponent id="notifications.warning" />
        break;
      case NotificationHelper.TYPE_ERROR:
        title = <IntlMessageComponent id="notifications.error" />
        break;
      default:
        throw new Error(`NotificationHelper - unsupported type ${type}`);
    }

    if (message) {
      const formattedMessage = <IntlMessageComponent id={message} />;
      NotificationManager[type](formattedMessage, title);
    } else {
      NotificationManager[type](title);
    }
  };
}
