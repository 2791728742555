/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import BooleanRadioFieldComponent from 'components/formFields/booleanRadio';
import IntlMessageComponent from 'components/formatters/intlMessage';
import OfficeFieldComponent from 'components/formFields/offices';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import RoleFieldConfirmingComponent from 'components/formFields/types/roleConfirming';
import ToolTipComponent from 'components/toolTips';

import CompanyUserForm from '../model';
import { CONFIRMING_ROLE_ADMINISTRATOR } from 'constants/constants';

type CompanyUsersFormCompanyInfoConfirmingPanelComponentProps = {
  errors: Map<string,?string>;
  form: CompanyUserForm;
  isProcessing: boolean;
  userPersonRoleIsActive: boolean;
  hasProcuration: boolean;
  hasProcurationUsers: boolean;
  hasProcurationGeneralConditions: boolean;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleBooleanChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleTypeChange: (fieldName: string) => (id: number) => void;
  newOffice: () => void;
}

export default function CompanyUsersFormCompanyInfoConfirmingPanelComponent(props: CompanyUsersFormCompanyInfoConfirmingPanelComponentProps) {
  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="page.profile.users.role" />
      <Box p={2} bgcolor="lightblue.dark">
        <IntlMessageComponent id="page.profile.confirming.rolebox" />
      </Box>
      {props.form.companyUserId === 0 && (<Box mt={3} px={4}>
        <BooleanRadioFieldComponent
          id="form.isAdministrator"
          error={!!props.errors.get('isAdministrator')}
          label={<IntlMessageComponent id="form.isAdministrator.addUser" />}
          name="isAdministrator"
          value={props.form.isAdministrator?.toString() || 'false'}
          onChange={props.handleBooleanChange('isAdministrator')}
        />
      </Box>
      )}

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>

        <Grid item xs={12} md={6}>
          <Box px={4}>
            <TextField
              id="form.functionName"
              label={<IntlMessageComponent id="form.functionName" />}
              error={!!props.errors.get('functionName')}
              helperText={props.errors.get('functionName') ?
                <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
              value={props.form.functionName}
              onChange={props.handleChange('functionName')}
              fullWidth
              margin="normal"
              required
            />
          </Box>
        </Grid>
        {props.userPersonRoleIsActive && <Grid item xs={12} md={6}>
          <Box px={4}>
            <RoleFieldConfirmingComponent
              id="form.confirmingRole"
              label={<IntlMessageComponent id="form.confirmingRole.label" />}
              error={!!props.errors.get('confirmingRoleId')}
              helperText={props.errors.get('confirmingRoleId') ?
                <IntlMessageComponent id={props.errors.get('confirmingRoleId')} /> : ''}
              value={props.form.confirmingRoleId}
              changeHandler={props.handleTypeChange('confirmingRoleId')}
              fullWidth
              margin="normal"
              required
              hasProcuration={props.hasProcuration}
              hasProcurationUsers={props.hasProcurationUsers}
              hasProcurationGeneralConditions={props.hasProcurationGeneralConditions}
              isAdministrator={props.form.isAdministrator}
              hasAdministratorRole={props.form.confirmingRoleId == CONFIRMING_ROLE_ADMINISTRATOR}
              InputProps={{
                startAdornment: (props.form.confirmingRoleId ?
                  <InputAdornment position="start">
                    <ToolTipComponent
                      pageName={"Tooltip"}
                      elementName={"confirming.roles.explanation"}
                    />
                  </InputAdornment> : "")
              }}
            />
          </Box>
        </Grid>}
      </Grid>

    </React.Fragment>
  );
}
