/* IN THIS FILE: PAGE URLS FOR REDIRECT INSIDE THE REACT SITE */

export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const LOST_PASSWORD = '/lostpw';
export const LEAD_BESTPRICING = '/bestpricing';

export const UNKNOWN_COMPANY_OFFER = '/offeruc';

export const PROTECTED_APP = '/app';

export const PROTECTED_APP_SUBSCRIPTION = '/app/subscription';
export const PROTECTED_APP_SUBSCRIPTION_TOS_CANCEL = '/app/subscription/terms-and-conditions/cancel';
export const PROTECTED_APP_SUBSCRIPTION_TOS_FAILURE = '/app/subscription/terms-and-conditions/failure';
export const PROTECTED_APP_SUBSCRIPTION_TOS_SUCCESS = '/app/subscription/terms-and-conditions/success';

export const PROTECTED_APP_TOS = '/app/terms-and-conditions';
export const PROTECTED_APP_TOS_CANCEL = '/app/terms-and-conditions/cancel';
export const PROTECTED_APP_TOS_FAILURE = '/app/terms-and-conditions/failure';
export const PROTECTED_APP_TOS_SUCCESS = '/app/terms-and-conditions/success';
export const PROTECTED_APP_CONTACTS = '/app/contacts';

export const PROTECTED_BUYER_BILLS = '/app/buyer/bills';
export const PROTECTED_BUYER_BUY_INVOICES = '/app/buyer/buy';
export const PROTECTED_BUYER_DASHBOARD = '/app/buyer';
export const PROTECTED_BUYER_INVOICE_DETAILS = '/app/buyer/invoice';
export const PROTECTED_BUYER_INVESTMENT_PROFILE = '/app/buyer/profile';
export const PROTECTED_BUYER_INVESTMENT_PROFILE_NEW = '/app/buyer/profile/new';
export const PROTECTED_BUYER_PERFORMANCE = '/app/buyer/performance';
export const PROTECTED_BUYER_PORTFOLIO = '/app/buyer/portfolio';
export const PROTECTED_BUYER_TRANSFER_INVOICES = '/app/buyer/transfer';

export const PROTECTED_COMPANY = '/app/company';
export const PROTECTED_COMPANY_BANKACCOUNTS = '/app/company/bankaccounts';
export const PROTECTED_COMPANY_ADD = '/app/company/add';
export const PROTECTED_COMPANY_OFFICES = '/app/company/offices';
export const PROTECTED_COMPANY_SUBSCRIPTIONS = '/app/company/subscriptions';
export const PROTECTED_COMPANY_UBOS = '/app/company/ubos';
export const PROTECTED_COMPANY_USERS = '/app/company/users';
export const PROTECTED_COMPANY_USERS_NEW = '/app/company/users/new';
export const PROTECTED_COMPANY_USERS_EDIT = '/app/company/users/{id}/edit';
export const PROTECTED_COMPANY_USERS_PROCURATION = '/app/company/users/{id}/procuration';
export const PROTECTED_COMPANY_DOCUMENTS = '/app/company/documents';
export const PROTECTED_COMPANY_FIXCONTRACT = '/app/company/fixcontract';

export const PROTECTED_DEBTOR_APPROVE_INVOICES = '/app/debtor/approve';
export const PROTECTED_DEBTOR_BILLS = '/app/debtor/bills';
export const PROTECTED_DEBTOR_COMMERCIAL = '/app/debtor/commercial';
export const PROTECTED_DEBTOR_DASHBOARD = '/app/debtor';
export const PROTECTED_DEBTOR_INVOICE_DETAILS = '/app/debtor/invoice';
export const PROTECTED_DEBTOR_PAY_INVOICES = '/app/debtor/pay';
export const PROTECTED_DEBTOR_PORTFOLIO = '/app/debtor/portfolio';
export const PROTECTED_DEBTOR_REGISTER_LEAD = '/app/debtor/lead';
export const PROTECTED_DEBTOR_REGISTER_SUPPLIER = '/app/debtor/supplier';
export const PROTECTED_DEBTOR_SUPPLIER_BANK_ACCOUNT = '/app/debtor/supplierbankaccount';

export const PROTECTED_CONFIRMING_DASHBOARD = '/app/confirming';
export const PROTECTED_CONFIRMING_SUBMIT = '/app/confirming/submit';
export const PROTECTED_CONFIRMING_BILLS = '/app/confirming/bills';

export const PROTECTED_ISO_COMMISSIONS = '/app/iso/commissions';
export const PROTECTED_ISO_COMMERCIAL = '/app/iso/commercial';
export const PROTECTED_ISO_COUPONS = '/app/iso/coupons';
export const PROTECTED_ISO_DASHBOARD = '/app/iso';
export const PROTECTED_ISO_INVOICES = '/app/iso/invoices';
export const PROTECTED_ISO_SELLERS = '/app/iso/sellers';
export const PROTECTED_ISO_LEADS = '/app/iso/leads';
export const PROTECTED_ISO_LEAD_DETAILS = '/app/iso/leaddetails'
export const PROTECTED_ISO_MANDATES = '/app/iso/mandates';
export const PROTECTED_ISO_OFFER = '/app/iso/offer';
export const PROTECTED_ISO_REGISTER_LEAD = '/app/iso/register-lead';
export const PROTECTED_ISO_REGISTER_SELLER = '/app/iso/register-seller';

export const PROTECTED_PROFILE = '/app/profile';
export const PROTECTED_PROFILE_CHANGEPASSWORD = '/app/profile/password';
export const PROTECTED_PROFILE_NOTIFICATIONS = '/app/profile/notifications';

export const PROTECTED_SELLER_BESTPRICING = '/app/seller/bestpricing';
export const PROTECTED_SELLER_BILLS = '/app/seller/bills';
export const PROTECTED_SELLER_DASHBOARD = '/app/seller';
export const PROTECTED_SELLER_INVOICE_DETAILS = '/app/seller/invoice';
export const PROTECTED_SELLER_MGM = '/app/seller/mgm';
export const PROTECTED_SELLER_OFFER = '/app/seller/offer';
export const PROTECTED_SELLER_PORTFOLIO = '/app/seller/portfolio';
export const PROTECTED_SELLER_REFFERAL = '/app/seller/referral';
export const PROTECTED_SELLER_SELL = '/app/seller/sell';
