/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ComponentWrapperComponent from 'components/websiteStructure/componentWrapper';
import Error404Component from 'components/errors/error404';
import ErrorTooLateComponent from 'components/errors/tooLate';
import ErrorWrongUrlComponent from 'components/errors/wrongUrl';
import InvoiceDocumentsComponent from 'components/invoiceDocuments';
import InvoiceMarketPlaceComponent from 'components/invoice/marketPlace';
import InvoiceBatchIconTooltipComponent from 'components/invoice/batchIcon';
import LoadingComponent from 'components/loading';
import PageTitleComponent from 'components/pageTitle';
import InvoiceNameComponent from 'components/invoice/name';
import IntlMessageComponent from 'components/formatters/intlMessage';
import DateFormatterComponent from 'components/formatters/date';
import BuyerRefuseInvoiceFormDialogComponent from 'components/refuseInvoiceDialog';

import BuyerInvoiceKPIsViewComponent from './kpis';
import BuyerInvoiceDetailsNotesViewComponent from './notes';
import BuyerInvoiceDetailsStatusComponent from './status';
import BuyerInvoiceBatchComponent from './batch';
import BuyerInvoiceDetailsSummaryComponent from './summary';

import BuyerInvoice from 'models/buyers/invoices/invoice';
import BuyerInvoiceBatch from 'models/buyers/invoices/invoiceBatch';
import InvoiceNotes from 'models/buyers/invoices/invoiceNotes';
import Kpi from 'models/dashboards/kpi';

import PurchaseInvoiceRequest from 'models/requests/buyers/purchaseInvoiceRequest';

import BuyerService from 'services/BuyerService';

import moment from 'moment/moment';

import { history } from 'store/index';
import { PROTECTED_BUYER_TRANSFER_INVOICES } from 'constants/pageRoutes';
import { PROTECTED_BUYER_BUY_INVOICES } from 'constants/pageRoutes';

type BuyerInvoiceDetailsComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  invoiceId: number;
  showInvestmentProfile: boolean;
  refuseSelected: string;
}

type BuyerInvoiceDetailsComponentState = {
  errorCode: number;
  invoice: BuyerInvoice;
  invoiceBatch: BuyerInvoiceBatch[];
  invoiceKPIs: Kpi[];
  invoiceNotes: InvoiceNotes;
  isLoading: boolean;
  isProcessing: boolean;
  statusKeys: string[];
  titleValues: { id: Element<any> };
  refuseInvoiceId: number;
}

class BuyerInvoiceDetailsComponent extends React.Component<BuyerInvoiceDetailsComponentProps, BuyerInvoiceDetailsComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      errorCode: 0,
      invoice: null,
      invoiceKPIs: null,
      invoiceNotes: null,
      isLoading: true,
      isProcessing: false,
      statusKeys: null,
      titleValues: { id: <b>{this.props.invoiceId}</b> }
    };
  }

  componentDidMount = async () => {
    try {
      const response = await BuyerService.getInvoiceDetails(this.props.activeCompanyId, this.props.invoiceId);
      if (response === null) {
        this.setState({
          isLoading: false,
          titleValues: this.getTitleValues(response.invoice)
        });
      } else {
        this.setState({
          isLoading: false,
          invoice: response.invoice,
          invoiceBatch: response.batch?.filter(b => !b.batchIsExcluded),
          invoiceKPIs: response.kpis,
          invoiceNotes: response.notes,
          statusKeys: response.statusKeys,
          titleValues: this.getTitleValues(response.invoice, response.batch && response.batch.length > 0),
          refuseInvoiceId: this.props.refuseSelected === 'refuse' ? response.invoice.id : 0
        });
      }
    } catch (error) {
      console.log(error);
      if (error.code === 403) {
        this.setState({ isLoading: false, errorCode: error.code });
      } else if (error.code === 400) {
        this.setState({ isLoading: false, errorCode: 404 });
      } else {
        this.setState({ isLoading: false, errorCode: 500 });
      }
    }
  }

  getTitleValues = (invoice: BuyerInvoice, hasBatch: boolean) => ({
    id: <b><InvoiceNameComponent invoiceId={invoice.id} invoiceName={invoice.invoiceName} /></b>,
    marketPlace: hasBatch ? <React.Fragment><InvoiceMarketPlaceComponent marketPlaceId={invoice.marketPlaceId} /><InvoiceBatchIconTooltipComponent /></React.Fragment> : <InvoiceMarketPlaceComponent marketPlaceId={invoice.marketPlaceId} />,
    reference: <b>{invoice.reference}</b>,
    investmentProfile: this.props.showInvestmentProfile && invoice.investmentProfile ? <b>({invoice.investmentProfile.name})</b> : null
  });

  refuseInvoice = () => this.setState({ refuseInvoiceId: this.state.invoice.id });
  closeRefuseInvoiceDialog = () => this.setState({ refuseInvoiceId: 0 });
  handleRefuseInvoiceSuccess = () => {
    history.push({
      pathname: PROTECTED_BUYER_BUY_INVOICES
    });
  }
  buyInvoice = async () => {
    this.setState({ isProcessing: true });

    try {
      const request = new PurchaseInvoiceRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, this.state.invoice.id);
      await BuyerService.purchaseInvoice(request);
      history.push({
        pathname: PROTECTED_BUYER_TRANSFER_INVOICES,
        state: { invoiceIds: [this.state.invoice.id] }
      });
    } catch (e) {
      this.setState({ isProcessing: false });
    }
  }

  payInvoice = () => history.push({
    pathname: PROTECTED_BUYER_TRANSFER_INVOICES,
    state: { invoiceIds: [this.state.invoice.id] }
  });

  isPaidWithChequeOrTraite = () => {
    return !this.state.invoice.toPay && !this.state.invoice.closedDate && (this.state.invoice.paymentType === 3330 || this.state.invoice.paymentType === 3329)
  }

  renderLoading() {
    return (
      <React.Fragment>
        <PageTitleComponent id="buyer.invoiceDetails.title.loading" values={this.state.titleValues} />
        <LoadingComponent />
      </React.Fragment>
    );
  }

  render() {
    if (this.state.isLoading) {
      return this.renderLoading();
    }

    // Maybe find a best way to do this
    if (this.state.errorCode === 404) {
      return (<Error404Component />); // invoice not found
    }
    if (this.state.errorCode === 403) {
      return (<ErrorWrongUrlComponent />); // invoice not for you
    }
    if (this.state.invoice === null) {
      return (<ErrorTooLateComponent />);//too late
    }

    return (
      <React.Fragment>
        <PageTitleComponent id="buyer.invoiceDetails.title" values={this.state.titleValues} backButton noBottomMargin={this.isPaidWithChequeOrTraite()} />
        {this.isPaidWithChequeOrTraite() && this.state.invoice.paymentType === 3330 && <Paper><Box p={1} mb={3}>
          <IntlMessageComponent id="buyer.invoiceDetails.paymentCheque" values={{ ReceivedDate: <DateFormatterComponent date={this.state.invoice.receivedDate} format="DD MMMM YYYY" /> }} />
        </Box></Paper>}
        {this.isPaidWithChequeOrTraite() && this.state.invoice.paymentType === 3329 && <Paper><Box p={1} mb={3}>
          <IntlMessageComponent id="buyer.invoiceDetails.paymentTraite" values={{ ValueDate: <DateFormatterComponent date={this.state.invoice.valueDate} format="DD MMMM YYYY" /> }} />
        </Box></Paper>}

        <ComponentWrapperComponent small>
          <div className="hiddenMobile">
            <BuyerInvoiceDetailsStatusComponent invoice={this.state.invoice} statusKeys={this.state.statusKeys} />
          </div>

          <Box mt={5}>
            <BuyerInvoiceDetailsSummaryComponent
              invoice={this.state.invoice}
              handleBuy={this.buyInvoice}
              handlePay={this.payInvoice}
              handleRefuse={this.refuseInvoice}
              currentInvestmentProfile={this.props.activeCompanyInvestmentProfileId}
            />
          </Box>
        </ComponentWrapperComponent>

        <Box mt={5}>
          {this.state.invoiceBatch && this.state.invoiceBatch.length > 0 && <BuyerInvoiceBatchComponent
            batch={this.state.invoiceBatch}
            showBalance={new moment(this.state.invoice.issueDate) > moment("23-02-2021", "DD-MM-YYYY")}
          />}
        </Box>

        <Box mt={5}>
          <BuyerInvoiceDetailsNotesViewComponent notes={this.state.invoiceNotes} />
        </Box>

        <Box mt={3}>
          <Grid container spacing={6} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <InvoiceDocumentsComponent invoiceId={this.state.invoice.id} allowAdd={false} />
            </Grid>

            <Grid item xs={12} md={6}>
              <BuyerInvoiceKPIsViewComponent kpis={this.state.invoiceKPIs} />
            </Grid>
          </Grid>
        </Box>
        {this.state.refuseInvoiceId > 0 &&
          <BuyerRefuseInvoiceFormDialogComponent
            invoiceId={this.state.refuseInvoiceId}
            closeDialog={this.closeRefuseInvoiceDialog}
            handleSuccess={this.handleRefuseInvoiceSuccess}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id,
  showInvestmentProfile: state.auth.user.activeCompany.roleBuyer.investmentProfiles.length > 1
});

export default connect(mapStateToProps)(BuyerInvoiceDetailsComponent);
