/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import IconSquare from 'components/icons/square';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import PercentageFormatterComponent from 'components/formatters/percentage';
import AverageOverdueDaysGrade from 'components/debtorGrades/debtorAverageOverdueDays';

import { kpiData } from './data';
import TooltipComponent from 'components/toolTips/index';

type ConfirmingKPIsViewComponentProps = {
  kpis: KPI[];
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.green.main
  }
}));

export default function ConfirmingKPIsViewComponent(props: ConfirmingKPIsViewComponentProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id="debtor.dashboard.kpis.title" data-walkme="ddashboard-title-reactivite" />

      <Box mt={3}>
        <Paper elevation={0}>
          <Box>
            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
              {props.kpis.map(kpi => {
                const cardInfo = kpiData.find(d => d.name === kpi.name);

                return (
                  <Grid item xs={12} sm={6} md={3} key={kpi.name} data-walkme={cardInfo.walkme} pageName="Tooltip.Dashboard.KPI" elementName={cardInfo.translationKey}>

                    <Box p={3}>
                      <Typography variant="body1" gutterBottom>
                        <TooltipComponent pageName="Tooltip.Dashboard.KPI" elementName={cardInfo.translationKey}>
                          <IntlMessageComponent id={cardInfo.translationKey} />
                        </TooltipComponent>
                      </Typography>

                      {cardInfo.type === 1 ? (
                        <React.Fragment>
                          <Rating
                            emptyIcon={<IconSquare color="disabled" fontSize="inherit" />}
                            icon={<IconSquare className={classes.root} fontSize="inherit" />}
                            max={10}
                            readOnly
                            value={Math.floor(kpi.value / 10)}
                          />

                          <Typography variant="body1">
                            <Box component="span" fontSize={30} fontWeight="fontWeightBold">
                              <PercentageFormatterComponent value={kpi.value} noFractionDigits />
                            </Box>
                          </Typography>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <AverageOverdueDaysGrade averageOverdueDays={kpi.value} />
                          <Typography variant="body1">
                            <Box component="span" fontSize={30} fontWeight="fontWeightBold">{kpi.value} <IntlMessageComponent id="general.days" /></Box>
                          </Typography>
                        </React.Fragment>
                      )}
                    </Box>

                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
