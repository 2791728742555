/* @flow */

import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CountryNameComponent from 'components/country/name';
import IntlMessageComponent from 'components/formatters/intlMessage';

import CompanyLookupBaseWithButtonComponent from '../base/button';
import SecurityComponent from 'components/security/index';

import SupplierCompany from 'models/companies/company';

type CompanyLookupSupplierTwoColumnsViewComponentProps = {
  companyErrorLabelId: string;
  countriesForSuppliers: Country[];
  knownSuppliers: SupplierCompany[];
  required: boolean;
  handleKnownSupplierCompanySelect: (company: SupplierCompany) => void;
  handleSelect: (company: SupplierCompany) => void;
}

export default function CompanyLookupSupplierTwoColumnsViewComponent(props: CompanyLookupSupplierTwoColumnsViewComponentProps) {

  return (
    <React.Fragment>
      <Grid container spacing={6} alignItems="stretch">
        {props.knownSuppliers && props.knownSuppliers.length > 0 &&
          <Grid item xs={12} md={6}>
            <Box px={4} py={3}>
              <Autocomplete
                getOptionLabel={(option) => option.name}
                onChange={(_, newValue) => props.handleKnownSupplierCompanySelect(newValue)}
                openOnFocus
                options={props.knownSuppliers}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    id="select-Supplier"
                    label={<IntlMessageComponent id="component.knownSuppliers.label" />}
                    fullWidth
                    margin="normal"
                  />
                }
                renderOption={(company) => (
                  <span>{company.name} - <CountryNameComponent countryId={company.countryId} /> [{company.vatNumber}]</span>
                )}
                value={null}
              />
            </Box>
          </Grid>
        }

        <Grid item xs={12} md={6}>
          <SecurityComponent pageName="POS.Offer.SupplierLookUp" elementName="btnAddNewCompanyLookUp">
            <CompanyLookupBaseWithButtonComponent
              companyErrorLabelId={props.companyErrorLabelId}
              countries={props.countriesForSuppliers}
              required={props.required}
              handleSelect={props.handleSelect}
              buttonLabel={'component.companyLookup.btn.new.supplier'}
            />
          </SecurityComponent>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
