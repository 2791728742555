/* @flow */

import React from 'react';
import {connect} from 'react-redux';

import CompanyDocumentsUploadViewComponent from './view';

import DocumentService from 'services/DocumentService';
import NotificationHelper from 'lib/notifications';

type CompanyDocumentsUploadComponentProps = {
  activeCompanyId: number;
  successHandler: () => void;
}

type CompanyDocumentsUploadComponentState = {
  isProcessing: boolean;
}

class CompanyDocumentsUploadComponent extends React.Component<CompanyDocumentsUploadComponentProps, CompanyDocumentsUploadComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false
    };
  }

  handleFail = () => {
    NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR, 'notifications.file.upload.wrongformat');
  }

  uploadFile = async (file: File) => {
    this.setState({isProcessing: true});

    try {
      await DocumentService.sendCompanyDocument(this.props.activeCompanyId, null, file);      
      this.setState({isProcessing: false});
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS, 'notifications.file.upload.success');
      this.props.successHandler();
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  render() {
    return (
      <CompanyDocumentsUploadViewComponent
        isProcessing={this.state.isProcessing}
        handleFail={this.handleFail}
        uploadFile={this.uploadFile}
      />
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CompanyDocumentsUploadComponent);
