/* @flow */


import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import PhoneNumberComponent from 'components/formFields/phoneNumber';
import ButtonComponent from 'components/buttons/button';
import CompanyLookupWithTypeComponent from 'components/companyLookupV2/types';
import CompanyTypeFormFieldComponent from 'components/formFields/companyType';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import MandatoryFormFieldsComponent from 'components/form/mandatory';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import RegisterCompanyForm from './model';

type SignUpTab1ViewComponentProps = {
  errors: Map<string,?string>;
  form: RegisterCompanyForm;
  formErrors: string[];
  isProcessing: boolean;
  countryId: number;
  handleChange: (fieldName: string) => (event: SyntheticInputEvent<HTMLInputElement>) => void;
  handleCompanyChange: () => void;
  handleCountryChange: () => void;
  handleCompanyTypeChange: () => void;
  handleMobileChange: (value: string) => void;
  isoNoCompany: () => void;
  submitForm: () => void;
}

export default function SignUpTab1ViewComponent(props: SignUpTab1ViewComponentProps) {
  return (
    <React.Fragment>
      <FormErrorsComponent formErrors={props.formErrors} />

      <Box pb={2}>
        <Alert severity="info">
          <IntlMessageComponent id="users.signup.secondcompanywarning" />
        </Alert>
      </Box>

      <PageSubTitleViewComponent id="page.signup.h2" />
      <Box px={4} py={3}>
        <CompanyTypeFormFieldComponent
          allowCompanyTypeDebtor
          allowCompanyTypeISO
          companyType={props.form.companyType}
          handleCompanyTypeChange={props.handleCompanyTypeChange}
        />
      </Box>

      <Box mt={5}>
        <Grid container spacing={6} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="signup.tab1.companyInfo" />

            <Box mt={3}>
              <CompanyLookupWithTypeComponent
                company={props.form.company}
                companyErrorLabelId={props.errors.get('company')}
                companyType={props.form.companyType}
                handleSelect={props.handleCompanyChange}
                handleCountry={props.handleCountryChange}
                required
              />
            </Box>


            {props.countryId === 144 && props.form.companyType === 3 &&
              <Box mt={5}>
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  isProcessing={props.isProcessing}
                  onClick={props.isoNoCompany}
                ><IntlMessageComponent id="general.button.isoNoCompany" /></ButtonComponent>
              </Box>
            }
          </Grid>

          <Grid item xs={12} md={6}>
            <PageSubTitleViewComponent id="signup.tab1.personalInfo" />

            <Box mt={3} px={4} py={3}>
              <TextField
                id="form.lastName"
                label={<IntlMessageComponent id="form.name.label" />}
                error={!!props.errors.get('lastName')}
                helperText={(props.errors.get('lastName')) ? <IntlMessageComponent id={props.errors.get('lastName')} /> : ''}
                value={props.form.lastName}
                onChange={props.handleChange('lastName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.firstName"
                label={<IntlMessageComponent id="form.firstname.label" />}
                error={!!props.errors.get('firstName')}
                helperText={(props.errors.get('firstName')) ? <IntlMessageComponent id={props.errors.get('firstName')} /> : ''}
                value={props.form.firstName}
                onChange={props.handleChange('firstName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.functionName"
                label={<IntlMessageComponent id="form.functionName" />}
                data-walkme="inscrire-field-fonction"
                error={!!props.errors.get('functionName')}
                helperText={(props.errors.get('functionName')) ?
                  <IntlMessageComponent id={props.errors.get('functionName')} /> : ''}
                value={props.form.functionName}
                onChange={props.handleChange('functionName')}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                id="form.email"
                label={<IntlMessageComponent id="form.email.label" />}
                data-walkme="inscrire-field-adresseemail"
                error={!!props.errors.get('email')}
                helperText={(props.errors.get('email')) ?
                  <IntlMessageComponent id={props.errors.get('email')} /> : ''}
                value={props.form.email}
                onChange={props.handleChange('email')}
                fullWidth
                margin="normal"
                required
              />

              <PhoneNumberComponent
                id="form.mobilePresence"
                label={<IntlMessageComponent id="form.mobile.label" />}
                data-walkme="inscrire-field-ndetelephone"
                error={!!props.errors.get('mobilePresence')}
                helperText={(props.errors.get('mobilePresence')) ?
                  <IntlMessageComponent id={props.errors.get('mobilePresence')} /> :
                  <IntlMessageComponent id="form.mobile.helperText" />}
                value={props.form.mobilePresence}
                onChange={props.handleMobileChange}
                fullWidth
                margin="normal"
                required
              />
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box mt={5}>
        <ButtonComponent
          color="primary"
          variant="contained"
          isProcessing={props.isProcessing}
          onClick={props.submitForm}
        ><IntlMessageComponent id="general.continue" /></ButtonComponent>

        <MandatoryFormFieldsComponent />
      </Box>
    </React.Fragment>
  );
}
