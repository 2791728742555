/* @flow */
import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Download from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import AmountFormatterComponent from 'components/formatters/amount';
import BilledTypeTranslator from 'components/translators/billedTypeTranslator';
import BillTypeTranslator from 'components/translators/billTypeTranslator';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import InvoiceNameComponent from 'components/invoice/name';
import StyledTableCellTextComponent from 'components/table/cellText';
import StyledTableCellNumbersComponent from 'components/table/cellNumbers';
import StyledTableHeadComponent from 'components/table/head';
import StyledTableRowComponent from 'components/table/row';
import SecurityComponent from 'components/security/index';

import Bill from 'models/documents/bill';

import { ROLE_BUYER, ROLE_SELLER, ROLE_DEBTOR } from 'constants/companyRoles';

const columnData = [
  { id: 'reference', align:'left', translationKey: 'component.bills.reference', walkme: 'accountancy-column-reference' },
  { id: 'issueDate', align:'left', translationKey: 'component.bills.issueDate', walkme: 'accountancy-column-issuedate' },
  { id: 'typeId', align:'left', translationKey: 'component.bills.type', walkme: 'accountancy-column-type' },
  { id: 'billedTypeId', align:'left', translationKey: 'component.bills.billedType', walkme: 'accountancy-column-subject' },
  { id: 'invoice', align:'left', translationKey: 'component.bills.invoice', walkme: 'accountancy-column-refedebex' },
  { id: 'invoiceReference', align:'left', role: ROLE_SELLER, translationKey: 'component.bills.invoiceReference', walkme: 'accountancy-column-refedebex' },
  { id: 'debtorCompanyName', align:'left', role: ROLE_BUYER, translationKey: 'component.bills.debtor', walkme: 'accountancy-column-debtor' },
  { id: 'debtorCompanyName', align:'left', role: ROLE_SELLER, translationKey: 'component.bills.client', walkme: 'accountancy-column-client' },
  { id: 'debtorCompanyName', align:'left', role: ROLE_DEBTOR, translationKey: 'component.bills.client', walkme: 'accountancy-column-client' },
  { id: 'amount', translationKey: 'component.bills.amount', walkme: 'accountancy-column-amount' }
];

type BillsPortfolioTableViewComponentProps = {
  bills: Bill[];
  order: string;
  orderBy: string;
  page: number;
  pageSize: number;
  downloadBill: (billId: number) => void;
  downloadSelectedBills: () => void;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortOrderChange: (property: string) => void;
  selectBill: (billId: number) => void;
  selectPageBills: () => void;
};

export default function BillsPortfolioTableViewComponent(props: BillsPortfolioTableViewComponentProps) {
  const activeCompanyRole = useSelector(state => state.auth.user.activeCompany.role);
  const billSlice = props.bills.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize)

  const handlePageChange = (_, page) => props.handlePageChange(page);
  const handlePageSizeChange = event => props.handlePageSizeChange(event.target.value);

  const onClickCheckbox = (event: SyntheticMouseEvent<>, billId: number) => {
    event.stopPropagation();
    props.selectBill(billId);
  };

  const onClickCheckboxAll = (event: SyntheticMouseEvent<>) => {
    event.stopPropagation();
    props.selectPageBills();
  };

  return (
    <React.Fragment>
      <Box py={5}>
        <Box component="div" overflow="auto">
          <Table>
            <StyledTableHeadComponent>
              <StyledTableRowComponent>
                <StyledTableCellTextComponent padding="checkbox">
                  <Tooltip title={<IntlMessageComponent id="general.tooltip.selectAll" />}>
                    <Checkbox
                      checked={billSlice.length > 0 && billSlice.find(i => !i.isSelected) === undefined}
                      color="primary"
                      disabled={billSlice.length === 0}
                      onClick={event => onClickCheckboxAll(event)}
                    />
                  </Tooltip>
                </StyledTableCellTextComponent>

                {columnData.filter(column => !column.role || column.role === activeCompanyRole).map(column => (
                  <StyledTableCellTextComponent align={column?.align ?? 'right'} key={column.id} padding="none">
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={props.order}
                      onClick={() => props.handleSortOrderChange(column.id)}
                      data-walkme={column.walkme || ''}
                    >
                      <IntlMessageComponent id={column.translationKey} />
                    </TableSortLabel>
                  </StyledTableCellTextComponent>
                ))}

                <StyledTableCellTextComponent padding="checkbox" />
              </StyledTableRowComponent>
            </StyledTableHeadComponent>

            <TableBody bgcolor="white">
              {props.bills.length === 0 &&
                <StyledTableRowComponent>
                  <StyledTableCellTextComponent colSpan={columnData.length + 2}>
                    <Box textAlign="center">
                      <IntlMessageComponent id="component.bills.noData" />
                    </Box>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              }

              {props.bills.slice(props.page * props.pageSize, (props.page * props.pageSize) + props.pageSize).map(bill => (
                <StyledTableRowComponent
                  key={`bill-${bill.id}`}
                  hover
                  tabIndex={-1}
                >
                  <StyledTableCellTextComponent padding="checkbox" onClick={event => onClickCheckbox(event, bill.id)}>
                    <Checkbox color="primary" checked={bill.isSelected} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {bill.reference}
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <DateFormatterComponent date={bill.issueDate} />
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    <strong><BillTypeTranslator id={bill.typeId} /></strong>
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {bill.billedTypeId > 0 &&
                      <strong><BilledTypeTranslator id={bill.billedTypeId} /></strong>
                    }
                  </StyledTableCellTextComponent>

                  <StyledTableCellTextComponent padding="none">
                    {bill.invoiceId ? <InvoiceNameComponent invoiceId={bill.invoiceId} invoiceName={bill.invoiceName} /> : '-'}
                  </StyledTableCellTextComponent>

                  {activeCompanyRole === ROLE_SELLER &&
                    <StyledTableCellTextComponent padding="none">
                      {bill.invoiceReference ? bill.invoiceReference : '-'}
                    </StyledTableCellTextComponent>
                  }

                  <StyledTableCellTextComponent padding="none">
                    {bill.debtorCompanyName ? bill.debtorCompanyName : '-'}
                  </StyledTableCellTextComponent>

                  <StyledTableCellNumbersComponent padding="none">
                    <AmountFormatterComponent amount={bill.amount} />
                  </StyledTableCellNumbersComponent>

                  <StyledTableCellTextComponent padding="checkbox">
                    <SecurityComponent pageName="POS.Companies.Bills" elementName="btnIndividualDownload">
                      <Tooltip title={<IntlMessageComponent id="general.tooltip.download" />}>
                        <IconButton onClick={() => props.downloadBill(bill.id)}>
                          <Download />
                        </IconButton>
                      </Tooltip>
                    </SecurityComponent>
                  </StyledTableCellTextComponent>
                </StyledTableRowComponent>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Box display="flex" height={60}>
          <Box flexGrow={1} mt={2}>
            <SecurityComponent pageName="POS.Companies.Bills" elementName="btnGeneralDownload">
              <ButtonComponent
                color="primary"
                variant="contained"
                disabled={props.bills.length <= 0 || props.bills.find(i => i.isSelected) === undefined}
                onClick={props.downloadSelectedBills}
                data-walkme="bills-button-download"
              ><IntlMessageComponent id="component.bills.btn.download" /></ButtonComponent>
            </SecurityComponent>
          </Box>
          <Box justifyContent="flex-end">
            <TablePagination
              component="div"
              count={props.bills.length}
              rowsPerPage={props.pageSize}
              page={props.page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
              labelRowsPerPage={<IntlMessageComponent id="component.bills.billsPerPage" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
