/* @flow */

import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import ButtonComponent from 'components/buttons/button';
import FormErrorsComponent from 'components/formErrors';
import IntlMessageComponent from 'components/formatters/intlMessage';
import DatePickerComponent from 'components/formFields/dates/datePicker';
import LeadLostReasonDetailFieldComponent from 'components/formFields/types/leadLostReasonDetail';

import CloseLeadForm from './model';

import IsoService from 'services/IsoService';
import { handleApiFormResponse, handleFormChange, handleFormValidation } from 'lib/forms';
import loadConstraints from 'lib/validation/loadConstraints';

import NotificationHelper from 'lib/notifications';

type CloseLeadFormDialogProps = {
  activeCompanyId: number;
  leadId: number;
  successHandler: () => void;
  cancelHandler: () => void;
}

type CloseLeadFormDialogState = {
  errors: Map<string,?string>;
  form: CloseLeadForm;
  formErrors: string[];
  isLoading: boolean;
  isProcessing: boolean;
}

class CloseLeadFormDialog extends React.Component<CloseLeadFormDialogProps, CloseLeadFormDialogState> {

  constructor(props: CloseLeadFormDialogProps) {
    super(props);

    this.state = {
      constraints: null,
      errors: null,
      form: new CloseLeadForm(),
      formErrors: [],
      isLoading: true,
      isProcessing: false
    };
  }

  componentDidMount = async () => {
    try {
      const constraints = await loadConstraints('lead', ['leadLostReasonDetailId']);

      this.setState({
        constraints,
        errors: new Map(Object.keys(constraints).map(e => [e, undefined])),
        isLoading: false
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (fieldName: string) => (event) => handleFormChange.call(this, fieldName, event.target.value);
  handleTypeChange = (fieldName: string) => (id: number) => handleFormChange.call(this, fieldName, id);
  handleDateChange = (fieldName: string) => (date: moment) => handleFormChange.call(this, fieldName, date);
  validateForm = () => handleFormValidation.call(this);

  submit = async () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ formErrors: [], isProcessing: true });

    try {
      const request = this.state.form.toRequest(this.props.activeCompanyId, this.props.leadId);
      await IsoService.closeLead(request);
      NotificationHelper.createNotification(NotificationHelper.TYPE_SUCCESS);
      this.props.successHandler();
    } catch (error) {
      handleApiFormResponse.call(this, error);
    }
  };

  isOtherSolutionReason = (lostReasonDetailId: number) => {
    if (lostReasonDetailId == 179 ||
      lostReasonDetailId == 180 ||
      lostReasonDetailId == 181 ||
      lostReasonDetailId == 182 ||
      lostReasonDetailId == 183 ||
      lostReasonDetailId == 184)
      return true;
    return false;
  }

  render() {
    if (this.state.isLoading) return null;

    return (
      <Dialog open onClose={this.props.cancelHandler}>

        <DialogTitle>
          <IntlMessageComponent id="component.isos.leads.closeLeadDialog.title" />
        </DialogTitle>

        <DialogContent>
          <FormErrorsComponent formErrors={this.state.formErrors} />

          <DialogContentText>
            <IntlMessageComponent id="component.isos.leads.closeLeadDialog.contentText" />
          </DialogContentText>

          <LeadLostReasonDetailFieldComponent
            id="form.leadLostReasonDetailId"
            label={<IntlMessageComponent id="component.isos.leads.closeDialog.leadLostReasonDetailId.label" />}
            error={!!this.state.errors.get('leadLostReasonDetailId')}
            helperText={this.state.errors.get('leadLostReasonDetailId') ?
              <IntlMessageComponent id={this.state.errors.get('leadLostReasonDetailId')} /> : ''}
            value={this.state.form.leadLostReasonDetailId}
            changeHandler={this.handleTypeChange('leadLostReasonDetailId')}
            fullWidth
            margin="normal"
            required
          />

          {this.isOtherSolutionReason(this.state.form.leadLostReasonDetailId) &&
            <TextField
              id="form.lostToCompetitor"
              multiline
              label={<IntlMessageComponent id="component.isos.leads.closeDialog.lostToCompetitor.label" />}
              helperText={this.state.errors.get('lostToCompetitor') ?
                <IntlMessageComponent id={this.state.errors.get('lostToCompetitor')} /> : ''}
              error={!!this.state.errors.get('lostToCompetitor')}
              onChange={this.handleChange('lostToCompetitor')}
              value={this.state.form.lostToCompetitor}
              autoFocus
              fullWidth
              margin="dense"
            />
          }

          {this.isOtherSolutionReason(this.state.form.leadLostReasonDetailId) &&
            <DatePickerComponent
              id="form.endOfContract"
              label={<IntlMessageComponent id="component.isos.leads.closeDialog.endOfContract.label" />}
              error={!!this.state.errors.get('endOfContract')}
              helperText={this.state.errors.get('endOfContract') ?
                <IntlMessageComponent id={this.state.errors.get('endOfContract')} /> : ''}
              value={this.state.form.endOfContract}
              onChange={this.handleDateChange('endOfContract')}
              fullWidth
              margin="normal"
            />
          }

        </DialogContent>

        <DialogActions>
          <ButtonComponent
            color="default"
            disabled={this.state.isProcessing}
            onClick={this.props.cancelHandler}
          ><IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.actions.cancel" /></ButtonComponent>

          <ButtonComponent
            color="primary"
            isProcessing={this.state.isProcessing}
            onClick={this.submit}
          ><IntlMessageComponent id="component.isos.leads.extendsLifetimeDialog.actions.submit" /></ButtonComponent>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  activeCompanyId: auth.user.activeCompany.id
});

export default connect(mapStateToProps)(CloseLeadFormDialog);
